import React from 'react';
import PageContainer from '../../components/common/PageContainer';
import { Button } from '../../components/common/Button';
import { useHistory } from 'react-router-dom';

const Error429 = () => {
  const history = useHistory();
  return (
    <PageContainer title="429 Too Many Requests">
      단시간에 너무 많은 요청을 시도했습니다. 잠시 기다린 후 다시 서비스를 이용해 주세요.
      <Button buttonType="action" onClick={() => history.goBack()}>
        이전 페이지로
      </Button>
    </PageContainer>
  );
};

export default Error429;
