import React, { forwardRef, useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line
import DatePicker from 'react-datepicker';
import SelectDate, { PeriodAsDays } from '../selectDate';
import { Calendar } from 'react-feather';
import { ko } from 'date-fns/locale';
import SelectDateFuture from '../selectDateFuture';
import moment from 'moment';
import { swalMessage } from '../common';
import usePrevious from '../../lib/hooks/usePrevious';
import { debounce } from 'lodash';

// interface DatePickerProps {
//   withCalendar?: boolean;
//   startDate: Date;
//   endDate: Date;
//   startMinDate?: Date;
//   startMaxDate?: Date;
//   endMinDate?: Date;
//   endMaxDate?: Date;
//   setStartDate: (value: React.SetStateAction<Date | null>) => void;
//   setEndDate: (value: React.SetStateAction<Date | null>) => void;
// }

// interface CalendarSelectProps {
//   value?: string;
//   onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
// }

const CalendarSelect = forwardRef(({ value, onClick }, ref) => (
  <DatePickerS onClick={onClick} ref={ref}>
    {value}
    <Calendar />
  </DatePickerS>
));

const DatePickerComponent = ({
  withCalendar,
  withFuture,
  maxPeriod,
  startDate,
  startMinDate,
  startMaxDate,
  endDate,
  endMinDate,
  endMaxDate,
  setStartDate,
  setEndDate,
  fetch,
}) => {
  const previousStartDate = usePrevious(startDate);
  const previousEndDate = usePrevious(endDate);
  /**
   * 변경된 시작, 종료 날짜에 따라 조회 가능 범위를 체크
   * 범위를 넘어서면 조회 가능 범위를 변경 후 fetch합니다.
   */
  useEffect(() => {
    if (withFuture || !maxPeriod || !fetch) return;
    try {
      if (previousStartDate !== startDate) {
        const term = moment(endDate).diff(moment(startDate), 'days');
        const currentMaxPeriod = parseInt(maxPeriod);
        if (term <= PeriodAsDays[currentMaxPeriod]) {
          fetch && fetch(startDate, endDate);
          return;
        }
        swalMessage(
          'warning',
          `최대 ${PeriodAsDays[currentMaxPeriod]}일 범위까지 조회 가능합니다.`,
          '확인',
          false,
        );
        const changedEndDate = moment(startDate)
          .add(PeriodAsDays[currentMaxPeriod], 'days')
          .toDate();

        setEndDate(changedEndDate);
        return fetchWithDebouncing(startDate, changedEndDate);
      }
      if (previousEndDate !== endDate) {
        const term = moment(endDate).diff(moment(startDate), 'days');
        const currentMaxPeriod = parseInt(maxPeriod);
        if (term <= PeriodAsDays[currentMaxPeriod]) {
          fetch && fetch(startDate, endDate);
          return;
        }
        swalMessage(
          'warning',
          `최대 ${PeriodAsDays[currentMaxPeriod]}일 범위까지 조회 가능합니다.`,
          '확인',
          false,
        );
        const changedStartDate = moment(endDate)
          .subtract(PeriodAsDays[currentMaxPeriod], 'days')
          .toDate();
        setStartDate(changedStartDate);
        return fetchWithDebouncing(changedStartDate, endDate);
      }
    } catch (error) {}
  }, [startDate, endDate]);

  const fetchWithDebouncing = debounce((...args) => fetch && fetch(...args), 1000);

  /**
   * 변경된 종료 날짜에 따라 조회 가능 범위를 체크
   * 범위를 넘어서면 조회 가능 범위를 종료 날짜 기준으로 변경 후 fetch합니다.
   */
  // useEffect(() => {
  //   if (withFuture || !maxPeriod || !fetch) return;
  //   try {
  //     const term = moment(endDate).diff(moment(startDate), 'days');
  //     const currentMaxPeriod = parseInt(maxPeriod);
  //     if (term <= PeriodAsDays[currentMaxPeriod]) {
  //       fetch && fetch(startDate, endDate);
  //       return;
  //     }
  //     swalMessage(
  //       'warning',
  //       `최대 ${PeriodAsDays[currentMaxPeriod]}일 범위까지 조회 가능합니다.`,
  //       '확인',
  //       false,
  //     );
  //     const changedStartDate = moment(endDate)
  //       .subtract(PeriodAsDays[currentMaxPeriod], 'days')
  //       .toDate();
  //     fetch && fetch(changedStartDate, endDate);
  //     setStartDate(changedStartDate);
  //   } catch (error) {}
  // }, [endDate]);

  return (
    <StyledDatePicker>
      <DatePicker
        selected={startDate}
        locale={ko}
        selectsStart
        minDate={startMinDate || undefined}
        maxDate={startMaxDate || undefined}
        onChange={(val) => setStartDate(val)}
        customInput={withCalendar ? <CalendarSelect /> : undefined}
      />
      <h5> ~ </h5>
      <DatePicker
        selected={endDate}
        locale={ko}
        selectsEnd
        minDate={endMinDate || undefined}
        maxDate={endMaxDate || undefined}
        onChange={(val) => setEndDate(val)}
        customInput={withCalendar ? <CalendarSelect /> : undefined}
      />
      {withFuture ? (
        <SelectDateFuture setStartDate={setStartDate} setEndDate={setEndDate} />
      ) : (
        <SelectDate setStartDate={setStartDate} setEndDate={setEndDate} maxPeriod={maxPeriod} />
      )}
    </StyledDatePicker>
  );
};

export default DatePickerComponent;

const DatePickerS = styled.button`
  background: #f8fcff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 6px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    /* margin-left: 2vw; */
    width: 17px;
    color: #ccc;
  }
  :active,
  :focus {
    border: 1px solid #05c8da;
  }
`;

const StyledDatePicker = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;

  h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 0 8px; */
    font-weight: 400;
    margin: 0;
  }
  > div > div > button {
    width: 100%;
  }
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;

    /* margin-left: 10px; */
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1rem auto auto;
    align-items: center;

    select,
    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    gap: 0.25rem;
    font-size: 0.75rem;
    svg {
      display: none;
    }
  }
`;
